import { useNavStore } from "@/store/navStore.ts";
import { AnimatePresence, motion } from "framer-motion";
import Stack from "@/components/atoms/Stack.tsx";
import NavButton from "@/components/atoms/NavButton.tsx";
import { useSubscriptionFilterStore } from "@/store/subscriptionFilterStore.ts";
import { Button } from "@/components/ui/button.tsx";
import Group from "@/components/atoms/Group.tsx";
import { useFormModalStore } from "@/store/modals/formModalStore.ts";
import type { Post } from "@/types/post";
import { H4 } from "@/components/atoms/text/Headings.tsx";
import PostCard from "@/components/organisms/cards/PostCard.tsx";
import IconX from "@/components/icons/IconX.tsx";
import { trackEvent } from "@/lib/utils/tracking";

const MobileMenu = ({ url, posts }: { posts: Post[]; url: any }) => {
  const { isOpen, close, toggle: toggleMobileMenu } = useNavStore();
  const { setOrder } = useSubscriptionFilterStore();
  const { open: openFormModal } = useFormModalStore();

  const scrollTo = (id: string) => {
    const element = document.getElementById(id);
    if (element) element.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: 100, opacity: 0 }}
          className={
            "z-10 overflow-y-auto p-4 h-full fixed bottom-0 bg-white w-full"
          }
        >
          <Group justify={"apart"}>
            <div className={"my-auto"}>
              <a href={"/"}>
                <img
                  className={"w-56"}
                  alt={"Mobilabonnement.no logo mørk"}
                  src={"/logo_dark.png"}
                  width={222}
                  height={18}
                />
              </a>
            </div>
            <button onClick={() => toggleMobileMenu()}>
              <IconX />
            </button>
          </Group>
          <Stack className={"w-full pt-6"}>
            <NavButton
              title={"Hjem"}
              href={"/"}
              onClick={close}
              currentPathname={url.pathname}
            />
            <NavButton
              title={"Bedrift"}
              href={"/bedrift"}
              onClick={close}
              currentPathname={url.pathname}
            />
            <NavButton
              title={"Sammenlign"}
              href={"/sammenlign"}
              onClick={close}
              currentPathname={url.pathname}
            />
            <NavButton
              title={"Billigste"}
              href={"/sammenlign"}
              onClick={() => {
                close();
                scrollTo("subscriptions");
                setOrder({
                  by: "price.monthly",
                  direction: "asc",
                });
              }}
            />
            <NavButton
              title={"Mest data"}
              href={"/sammenlign"}
              onClick={async () => {
                close();
                scrollTo("subscriptions");
                setOrder({
                  by: "attribute.data",
                  direction: "desc",
                });
              }}
            />
            <Button
              className={"w-full"}
              onClick={() => {
                trackEvent("get_offer_clicked", {
                  placement: "mobile_menu",
                });

                openFormModal();
              }}
            >
              Få et uforpliktende tilbud
            </Button>
          </Stack>
          <div className={"my-4 h-[1px] bg-gray-200"}></div>
          <Stack>
            <H4>Siste artikler</H4>
            <Stack>
              {posts?.map((post, index) => (
                <PostCard post={post} key={index} onClick={close} />
              ))}
            </Stack>
          </Stack>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default MobileMenu;
